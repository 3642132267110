import { Divider, IconButton, Tooltip, Drawer } from '@mui/material';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { matchPath, withRouter } from 'react-router-dom';
import withStyles from '@mui/styles/withStyles';
import CloseIcon from '@mui/icons-material/Close';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import { toggleDrawerMenu } from './layout.actions';
import { useCheckPermission } from '../../hooks/useCheckPermission';
import logo from '../../images/login/logo.png';
import routeLinks from './routeLinks';

export const DRAWER_WIDTH = 300;

const DrawerMenu = ({classes, isDrawerMenuOpen, toggleDrawerMenu, viewPage, location}) => {
  const {isAuthorized} = useCheckPermission();
  
  const isActiveRoute = (route) => {
    const { pathname } = location;

    //explicitly return true/false because matchpath doesn't return a boolean
    if (
      matchPath(pathname, {
        path: route,
        exact: false,
        strict: false,
      })
    ) {
      if (route === '/' && pathname !== '/') {
        return false;
      }
      return true;
    }

    return false;
  };

  return (
    <Drawer
      variant="permanent"
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isDrawerMenuOpen,
        [classes.drawerClose]: !isDrawerMenuOpen,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isDrawerMenuOpen,
          [classes.drawerClose]: !isDrawerMenuOpen,
        }),
      }}
      open={isDrawerMenuOpen}
    >
      <div className={classes.toolbar}>
        <img src={logo} className={classNames(classes.logo)} alt="mamava logo" />
        <IconButton onClick={toggleDrawerMenu} size="large">
          <CloseIcon />
        </IconButton>
      </div>
      <Divider />
      <List className={classes.list}>
        {routeLinks.map((routeLink) => {
          const RouteIcon = routeLink.icon;
          return (
            isAuthorized(routeLink.permission) && 
            <Tooltip title={routeLink.name} disableHoverListener={isDrawerMenuOpen} key={routeLink.name}>
              <ListItem 
                button
                onClick={() => viewPage(routeLink.route, routeLink.preNavHandler)}
                selected={isActiveRoute(routeLink.route)}
              >
                <ListItemIcon className={classes.listItem}>
                  <RouteIcon />
                </ListItemIcon>
                <ListItemText primary={routeLink.name} className={classes.routeTitle} />
               
              </ListItem>
            </Tooltip>
          );
        })}
      </List>
    </Drawer>
  );
};

const styles = (theme) => ({
  drawerOpen: {
    width: DRAWER_WIDTH,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  logo: {
    height: 50,
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  routeTitle: {
    whiteSpace: 'nowrap',
  },
  list: {
    padding: 0,
  },
  listItem: {
    paddingLeft: 8,
  },
});

DrawerMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  isDrawerMenuOpen: PropTypes.bool,
  location: PropTypes.object,
  viewPage: PropTypes.func.isRequired,
  toggleDrawerMenu: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {    
    isDrawerMenuOpen: state.layout.get('isDrawerMenuOpen'),
  };
};

const prepareForExport = compose(
  withStyles(styles, {withTheme: true}),
  withRouter,
  connect(
    mapStateToProps,
    {
      toggleDrawerMenu,
    }
  )
);

export default prepareForExport(DrawerMenu);