import { Menu, MenuItem } from '@mui/material';
import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import { matchPath, withRouter } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AppBar from '@mui/material/AppBar';
import BackIcon from '@mui/icons-material/ChevronLeft';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import PropTypes from 'prop-types';
import React, { Fragment, useMemo, useState } from 'react';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import classNames from 'classnames';
import withStyles from '@mui/styles/withStyles';

import { setDirtyForm, toggleDrawerMenu } from './layout.actions';
import DrawerMenu, { DRAWER_WIDTH } from './drawerMenu.component';
import colors from '../../styles/colors';
import routeLinks from './routeLinks';

const Header = ({classes, location, history, isDrawerMenuOpen, hasDirtyForm, pageTitle, toggleDrawerMenu, setDirtyForm }) => {

  const [anchorEl, setAnchorEl] = useState(null);

  const { isLoading, user, logout } = useAuth0();
  const dispatch = useDispatch();

  const isMenuOpen = useMemo(() => Boolean(anchorEl), [anchorEl]);

  const shouldShowBackButton = useMemo(() => {
    const { pathname } = location;

    const nonTerminalRoutes = routeLinks.map((routeLink) => routeLink.route);

    let toReturn = true;
    for (let route of nonTerminalRoutes) {
      if (
        matchPath(pathname, {
          path: route,
          exact: true,
          strict: false,
        })
      ) {
        toReturn = false;
      }
    }

    // default
    return toReturn;
  }, [location]);

  const isActiveRoute = (route) => {
    const { pathname } = location;

    //explicitly return true/false because matchpath doesn't return a boolean
    if (
      matchPath(pathname, {
        path: route,
        exact: true,
        strict: false,
      })
    ) {
      return true;
    }

    return false;
  };

  const viewPage = (route, preNavHandler) => {
    if (preNavHandler) {
      preNavHandler(dispatch);
    }
    if (!isActiveRoute(route)) {
      if (route.indexOf('http') > -1) {
        window.open(route, '_blank');
      } else {
        history.push(route);
      }
    }
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem disabled>{!isLoading && !isEmpty(user) && user.name}</MenuItem>
      <MenuItem onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>Log Out</MenuItem>
    </Menu>
  );

  return (
    <Fragment>
      <AppBar position="fixed"  className={classNames(classes.appBar, {
        [classes.appBarShift]: isDrawerMenuOpen,
      })}
      >
        <Toolbar className={classes.toolbar} disableGutters={!isDrawerMenuOpen}>

          <div className={classes.toolbarContents}>
            
            <IconButton
              className={classNames(classes.menuButton, {
                [classes.hide]: isDrawerMenuOpen,
              })}
              color="inherit"
              aria-label="Menu"
              onClick={() => {
                toggleDrawerMenu();
              }}
              size="large">
              <MenuIcon />
            </IconButton>
            
            {shouldShowBackButton && (
              <IconButton
                className={classes.backButton}
                color="inherit"
                aria-label="Back"
                onClick={() => {
                  if (hasDirtyForm) {
                    console.log('Had dirty form... manually assessing prompt');
                    if (window.confirm('You have unsaved changes. Clicking OK will not save these changes.')) {
                      setDirtyForm(false);
                      history.goBack();
                    }
                  } else {
                    history.goBack();
                  }
                }}
                size="large">
                <BackIcon />
              </IconButton>
            )}

            <div className={classes.pageTitle}>
              <Typography variant="h6" color="inherit" className={classes.flex}>
                {/* |&nbsp; */}
                {pageTitle}
              </Typography>
            </div>

            <IconButton
              aria-owns={isMenuOpen ? 'material-appbar' : undefined}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
              size="large">
              <AccountCircleIcon />
            </IconButton>
          </div>

        </Toolbar>
      </AppBar>
      {renderMenu}
      <DrawerMenu
        isDrawerMenuOpen={isDrawerMenuOpen}
        toggleDrawerMenu={toggleDrawerMenu}
        viewPage={viewPage}
      />
    </Fragment>
  );
};

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    backgroundColor: colors.grayDark,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: DRAWER_WIDTH,
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  logo: {
    maxHeight: '2.5rem',
    marginRight: '1rem',
  },
  flex: {
    flex: 1,
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  backButton: {
    paddingLeft: 0,
  },
  hide: {
    display: 'none',
  },
  toolbar: {
    paddingRight: '0',
  },
  toolbarContents: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  pageTitle: {
    flex: 1,
  },
});

Header.propTypes = {
  responsiveBreakpoint: PropTypes.object.isRequired,
  pageTitle: PropTypes.string.isRequired,
  location: PropTypes.object,
  history: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  isDrawerMenuOpen: PropTypes.bool,
  toggleDrawerMenu: PropTypes.func.isRequired,
  setDirtyForm: PropTypes.func.isRequired,
  hasDirtyForm: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    responsiveBreakpoint: state.layout.get('responsiveBreakpoint'),
    pageTitle: state.layout.get('pageTitle'),
    isDrawerMenuOpen: state.layout.get('isDrawerMenuOpen'),
    hasDirtyForm: state.layout.get('hasDirtyForm'),
  };
};


const prepareForExport = compose(
  withStyles(styles),
  withRouter,
  connect(
    mapStateToProps,
    {
      toggleDrawerMenu,
      setDirtyForm,
    }
  )
);

export default prepareForExport(Header);
