import AllInboxIcon from '@mui/icons-material/AllInbox';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import PeopleIcon from '@mui/icons-material/People';
import PhonelinkSetupIcon from '@mui/icons-material/PhonelinkSetup';

import { PERMISSIONS } from '../auth/constants';
import { clearLocations, clearSearchFilters } from '../locations/locations.actions';

export const LOCATIONS = {
  route: '/locations',
  name: 'Locations',
  icon: LocationOnIcon,
  permission: PERMISSIONS.ACCESS_LOCATIONS,
  preNavHandler: (dispatch) => {
    dispatch(clearLocations());
    dispatch(clearSearchFilters());
  },
};

export const SUBMISSIONS = {
  route: '/submissions',
  name: 'Submissions',
  icon: AllInboxIcon,
  permission: PERMISSIONS.ACCESS_SUBMISSIONS,
};

export const USERS = {
  route: '/users',
  name: 'Users',
  icon: PeopleIcon,
  permission: PERMISSIONS.ACCESS_USERS,
};

export const PROMOTIONS = {
  route: '/promotions',
  name: 'Promotions',
  icon: NewReleasesIcon,
  permission: PERMISSIONS.ACCESS_PROMOTIONS,
};

export const PROMOTION_SLOTS = {
  route: '/promotionSlots',
  name: 'Promotion App Slots',
  icon: PhonelinkSetupIcon,
  permission: PERMISSIONS.ACCESS_PROMOTIONS_APP_SLOTS,
};

export default [
  LOCATIONS,
  SUBMISSIONS,
  PROMOTIONS,
  PROMOTION_SLOTS,
  USERS,
];
